<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลเครดิต
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Username</label>
                <input type="text" v-model="result.username" class="form-control" placeholder="ข้อมูล Username" >
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ชื่อบัญชี</label>
                <input type="text" v-model="result.full_name" class="form-control" placeholder="ข้อมูลชื่อบัญชี" >
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ประเภท</label>
                <input type="text" v-model="type[result.type]" class="form-control" placeholder="ข้อมูลประเภท" >
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">จำนวน</label>
                <input type="text" v-model="result.process" class="form-control" placeholder="ข้อมูลจำนวน" >
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ยอดก่อนหน้านี้</label>
                <input type="text" v-model="result.credit_before" class="form-control" placeholder="ข้อมูลยอดก่อนหน้านี้" >
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ยอดคงเหลือ</label>
                <input type="text" v-model="result.credit_after" class="form-control" placeholder="ข้อมูลยอดคงเหลือ" >
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">วันเวลา</label>
                <input type="text" class="form-control" placeholder="ข้อมูลเวลา" :value="moment(result.created_at)" >
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">รายละเอียด</label>
                <input type="text" v-model="detail[result.credit_before != result.credit_after]" class="form-control" placeholder="ข้อมูลรายละเอียด" >
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ทำรายการโดย</label>
                <input type="text" v-model="result.admin_username" class="form-control" placeholder="ข้อมูลทำรายการโดย" >
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-card no-body class="d-none">
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลธนาคาร
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ธนาคาร</label>
                <b-form-select v-model="result.bank" :options="option_bank" disabled/>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">เลขบัญชี</label>
                <input type="text" v-model="result.bank_number" class="form-control" placeholder="ข้อมูลเลขบัญชี" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ชื่อบัญชี</label>
                <input type="text" v-model="result.full_name" class="form-control" placeholder="ข้อมูลชื่อบัญชี" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">จำนวนเงิน</label>
                <input type="text" v-model="result.amount" class="form-control" placeholder="ข้อมูลจำนวนเงิน" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ถอนเมื่อ</label>
                <input type="text" v-model="result.created_at" class="form-control" placeholder="ข้อมูลถอนเมื่อ" readonly>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดท้าย -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ประวัติการปรับเครดิต
          </h4>
          <span class="bullet bullet-sm mr-1 bullet-success"></span>
          <span>เพิ่มเครติดอัตโนมัติ</span>
          <span class="ml-2 bullet bullet-sm mr-1 bullet-warning"></span>
          <span>ลดเครติดอัตโนมัติ</span>
          <span class="ml-2 bullet bullet-sm mr-1 bullet-info"></span>
          <span>เครดิตที่กำลังตรวจสอบ</span>
          <span class="ml-2 bullet bullet-sm mr-1 bullet-danger"></span>
          <span>เครดิตที่เติมผ่านหน้า AG</span>
          <hr />
          <b-row>
            <b-col cols="md-12">
             <b-table-lite hover :items="items" :fields="fields">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(description)="data">
                <b-badge :variant="status[0][data.item.transaction != result.id ? data.item.status : 4]">
                  {{ data.value }}
                </b-badge>
              </template>
             </b-table-lite>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="PATH" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1" /></span> ย้อนกลับ</router-link>
                  <b-button @click="fetch()" class="btn bg-gradient-primary waves-effect waves-light mr-1"><span><feather-icon icon="RefreshCcwIcon" class="font-medium-1" /></span> รีเฟรชประวัติ</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BFormGroup, BFormSelect, BTableLite, BBadge, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BTableLite,
    BFormGroup,
    BFormSelect,
    BBadge,
    BButton,
    vSelect,
  },
  data() {
    return {
      PATH: '/credit',
      BASE_URL: process.env.BASE_URL,
      API_GET_URL: 'credit/credit_detail/' + this.$route.params.id,
      result: {},
      fields: [
        { key: 'index', label: '#', class: 'text-center' },
        { key: 'username', label: 'username', class: 'text-center' },
        { key: 'time', label: 'เวลา', class: 'text-center' },
        { key: 'before', label: 'จากค่า', class: 'text-right' },
        { key: 'after', label: 'เป็นค่า', class: 'text-right' },
        { key: 'amount', label: 'จำนวน Ag', class: 'text-right' },
        { key: 'deposit', label: 'ยอดฝาก', class: 'text-right' },
        { key: 'withdraw', label: 'ยอดถอน', class: 'text-right' },
        { key: 'description', label: 'รายละเอียด', class: 'text-center' },
        { key: 'admin_username', label: 'ทำรายการโดย', class: 'text-center' },
      ],
      items: [],
      status: [
        { 1: 'success', 2: 'warning', 3: 'danger', 4: 'info' }
      ],
      type: {
        1: 'เพิ่มเครดิต',
        2: 'ลดเครดิต',
        3: 'โปรเครดิต',
        4: 'ฟรีเครดิต',
        5: 'รายการเช็ค',
      },
      detail: {
        true: 'ทำรายการสำเร็จ', false: 'ทำรายการไม่สำเร็จ'
      },
      option_bank: [
        { text: 'ธนาคารกรุงเทพ จำกัด (มหาชน)', value: '01' },
        { text: 'ธนาคารกสิกรไทย จำกัด (มหาชน)', value: '02' },
        { text: 'ธนาคารกรุงไทย จำกัด (มหาชน)', value: '03' },
        { text: 'ธนาคารทหารไทยธนชาต จำกัด (มหาชน)', value: '04' },
        { text: 'ธนาคารไทยพาณิชย์ จำกัด (มหาชน)', value: '05' },
        { text: 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)', value: '06' },
        { text: 'ธนาคารออมสิน จำกัด (มหาชน)', value: '07' },
        { text: 'ธนาคารธนชาติ จำกัด (มหาชน)', value: '08' },
        { text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร จำกัด (มหาชน)', value: '09' },
      ],
      option: [
        { text: 'ปิด', value: '0' },
        { text: 'เปิด', value: '1' },
      ],
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    moment: (date) => {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    fetch() {
      this.$http.get(this.API_GET_URL).then(res => {
        if (res.data != null && res.data.result != null) {
          this.result = res.data.result
          this.result.bank_code = this.option_bank.find(b=>b.value==this.result.bank_code)
          this.result.bonus = 0
          this.items = res.data.history
        }
        else this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
    slip() {

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
